<template>
  <div>
    <v-row class="px-2">
      <v-col cols="12">
        <view-header :subtitle="email">
          <template #title>
            {{ title }}
            <v-chip v-if="isLoggedInUser" small>You</v-chip>
          </template>
          <template #actions>
            <v-tooltip bottom v-if="!isLoggedInUser && canDeleteUser">
              <template #activator="{on}">
                <v-btn append icon v-on="on" @click="dialogOpen = true">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete User</span>
            </v-tooltip>
          </template>
        </view-header>
      </v-col>
    </v-row>
    <v-divider class="divider-dark"/>
    <v-row class="align-center px-2">
      <v-col cols="4" md="2">Linked Person</v-col>
      <v-col cols="8" md="10">
        <template v-if="hasLinkedPerson">
          <v-btn text :to="{name: 'person', params: {id: user.person.ref.id}}" class="text-none">
            <v-icon left>mdi-account-check</v-icon>
            {{ user.person.title }}
          </v-btn>
          <v-tooltip bottom>
            <template #activator="{on}">
              <v-btn icon v-on="on" @click="removePersonLink" :disabled="isLoggedInUser">
                <v-icon>mdi-link-off</v-icon>
              </v-btn>
            </template>
            Remove this user's person link.
          </v-tooltip>
        </template>
        <user-link-person
            v-else
            @link="linkTo"
            :loading="linkingPerson"
            v-model="linkingDialog"
            :disabled="isLoggedInUser"/>
      </v-col>
    </v-row>
    <v-divider/>
    <v-row class="px-2">
      <v-col cols="12">
        <h2 class="headline">Roles</h2>
      </v-col>
    </v-row>
    <v-form @submit.prevent="saveChanges">
      <template v-for="group in roleGroupValues">
        <v-row class="px-2" :key="group.title">
          <v-col cols="12" sm="4" md="2" class="role-label">
            <h3 class="sticky-title py-3 subtitle-1">{{ group.title }}</h3>
          </v-col>
          <v-col cols="12" sm="8" md="10">
            <v-list class="py-0">
              <template v-for="role in group.roles">
                <v-list-item
                    v-if="role.key !== 'ns.admin' || authUserIsAdmin"
                    two-line
                    :key="role.key"
                    :disabled="isLoggedInUser || role.impliedBy.length > 0"
                    @click="role.value ? removeRole(role.key) : addRole(role.key)"
                    class="enable-pointers pl-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ role.title }}</v-list-item-title>
                    <v-list-item-subtitle class="text-wrap">{{ role.help }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip bottom :disabled="role.impliedBy.length === 0">
                      <template #activator="{on, attrs}">
                        <span
                            v-bind="attrs"
                            v-on="on">
                          <v-checkbox
                              :input-value="role.value"
                              :disabled="isLoggedInUser || role.impliedBy.length > 0"
                              color="accent darken-1"
                              class="enable-pointers"/>
                        </span>
                      </template>
                      Implied by {{ role.impliedBy.map(r => r.title).join(', ') }}
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
        <v-divider :key="group.title + ':divider'"/>
      </template>
      <div class="pa-3 d-flex justify-end">
        <v-btn
            type="submit"
            color="accent"
            :loading="savingPermissions"
            :disabled="isLoggedInUser || !rolesHaveChanged">
          Apply
        </v-btn>
      </div>
    </v-form>
    <template v-if="sitesAreShown">
      <v-row class="px-2">
        <v-col cols="12" lg="5" md="8" class="sites-header">
          <div class="headline">Sites</div>
          <div class="px-4">
            <v-checkbox
                class="v-input--reverse ma-0"
                color="accent darken-1"
                hide-details
                :disabled="isLoggedInUser || !sitesAreEditable"
                :input-value="sitesSelectAll"
                @change="selectAllSites">
              <template #label>Select All</template>
            </v-checkbox>
          </div>
          <h4 class="subtitle-2 grey--text text--lighten-2">Select which sites this user can manage</h4>
        </v-col>
      </v-row>
      <v-row class="px-2">
        <v-col cols="12" lg="5" md="8">
          <v-form @submit.prevent="saveSiteChanges">
            <user-sites-editor
                ref="siteEditor"
                @changes="siteChanges = $event"
                @sites="sites = $event"
                @all-selected="sitesSelectAll = $event"
                :editable="!isLoggedInUser || sitesAreEditable"
                :auth-user-is-admin="authUserIsAdmin"/>
            <div class="pa-3 d-flex justify-end">
              <v-btn
                  type="submit"
                  color="accent"
                  :loading="savingSites"
                  :disabled="isLoggedInUser || !sitesAreEditable || !siteChanges">
                Apply
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </template>
    <v-dialog v-model="dialogOpen" width="450px">
      <v-card class="ma-auto">
        <v-toolbar flat color="warning">
          <v-icon size="28" color="primary-text">mdi-alert-circle</v-icon>
          <span class="mx-2 text-h5 primary-text--text">{{ email }}</span>
        </v-toolbar>
        <v-card-text class="my-2">
          <slot>
            <span>Are you sure you want to delete this user?</span>
          </slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="dialogOpen = false">
            Cancel
          </v-btn>
          <v-btn
              depressed
              color="warning"
              @click="confirmDeleteUser">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserLinkPerson from '@/components/users/UserLinkPerson';
import ViewHeader from '@/components/ViewHeader';
import {cloneDecoratedData} from '@/firebase';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import UserSitesEditor from '@/views/people/users/components/UserSitesEditor';
import {functionUtil} from '@/firebase';

export default {
  name: 'UserView',
  components: {UserSitesEditor, UserLinkPerson, ViewHeader},
  data() {
    return {
      // used for change detections and deferred commit
      user: null,
      savingPermissions: false,
      savePermissionsError: null,
      linkingPerson: false,
      linkingDialog: false,
      savingSites: false,
      siteChanges: false,
      sitesSelectAll: false,
      sites: [],
      dialogOpen: false
    };
  },
  computed: {
    ...mapGetters('views/user', {originalUser: 'user'}),
    ...mapGetters('auth', {authUserIsAdmin: 'isAdmin'}),
    ...mapGetters('auth', ['canDeleteUser']),
    title() {
      if (!this.user) {
        return '';
      }
      if (this.user.title) {
        return this.user.title;
      }
      if (this.user.person && this.user.person.title) {
        return this.user.person.title;
      }
      return '';
    },
    email() {
      return (this.user && this.user.email) || '';
    },
    isLoggedInUser() {
      return this.user && this.user.id === this.$store.getters['user/getUser'].id;
    },
    hasLinkedPerson() {
      return this.user && this.user.person && this.user.person.ref;
    },
    rolesHaveChanged() {
      const roles = new Set(this.originalUser.roles);

      // There can be a case where the User being edited doesn't have a roles property,
      // but we manually create it when a user selects a role to assign,
      // so this function still works, as user.roles will become non-null and be checked
      // See addRole() function.
      if (!this.user.roles) {
        return false;
      }

      return this.user.roles.length !== roles.size || !this.user.roles.every(r => roles.has(r));
    },
    // combine the roleGroups with the current users roles into a single structure
    roleGroupValues() {
      const roles = new Set(this.user.roles);
      const inheritedRoles = this.inheritedRoles;
      // used to check whether the role is enabled, or a role that inherits this is
      const calcValue = role => {
        let value = false;
        const impliedBy = [];

        const rolesChecked = new Set();
        const rolesToCheck = [role];
        while (rolesToCheck.length > 0) {
          const toCheck = rolesToCheck.pop();
          if (rolesChecked.has(toCheck.key)) {
            // need this because we don't filter when adding to the rolesToCheck list based on roles that are already
            // in the list but not checked yet
            continue;
          }
          // avoid loops
          rolesChecked.add(toCheck.key);

          if (roles.has(toCheck.key)) {
            value = true;
            if (role.key !== toCheck.key) {
              // only implied if the current toCheck role isn't the role we started at
              impliedBy.push(toCheck);
            }
          }

          // check up the tree
          const inheritFromThis = inheritedRoles[toCheck.key];
          if (inheritFromThis) {
            rolesToCheck.push(...inheritFromThis.filter(r => !rolesChecked.has(r.key)));
          }
        }

        return {
          value,
          impliedBy
        };
      };
      return this.roleGroups.map(group => ({
        ...group,
        roles: group.roles.map(role => ({
          ...role,
          ...calcValue(role)
        }))
      }));
    },
    // reorganise the roleGroups into a map representing roles that inherit from other roles
    inheritedRoles() {
      const result = {};
      this.roleGroups.forEach(group => {
        group.roles.forEach(role => {
          if (role.inherit) {
            role.inherit.forEach(inheritFrom => {
              result[inheritFrom] = result[inheritFrom] || [];
              result[inheritFrom].push(role);
            });
          }
        });
      });
      return result;
    },
    roleGroups() {
      return this.$store.state.appConfig.roles;
    },
    isAdmin() {
      if (!this.user) {
        return false;
      }
      const roles = this.user.roles;
      if (!roles) {
        return false;
      }
      return roles.includes('ns.admin');
    },
    sitesAreEditable() {
      return !this.isAdmin;
    },
    sitesAreShown() {
      if (!this.user || !this.user.roles) {
        return false;
      }
      const siteRoles = ['ns.admin', 'people.admin', 'people.reception', 'desk-booking.admin'];
      return siteRoles.some(r => this.user.roles.includes(r));
    }
  },
  watch: {
    originalUser: {
      // Don't need deep:true here as the backing model will replace the object on change anyway
      immediate: true,
      handler: 'copyFields'
    },
    sitesAreEditable: {
      immediate: true,
      handler() {
        // wait for the $ref to exist for this.selectAllSites
        this.$nextTick(() => {
          if (!this.sitesAreEditable) {
            this.selectAllSites(true);
          } else if (this.$refs.siteEditor) {
            this.$refs.siteEditor.resetSelected();
          }
        });
      }
    }
  },
  methods: {
    ...mapActions('views/user', ['linkToPerson', 'unlinkPerson', 'updateUser']),
    ...mapMutations('views/users', ['removeUser']),
    saveChanges() {
      this.savingPermissions = true;
      this.updateUser(this.user)
          .then(() => {
            this.savePermissionsError = null;
            if (this.title) {
              this.$notify.showSuccess(`Roles for ${this.title} have been successfully updated`);
            } else {
              this.$notify.showSuccess(`Roles have been successfully updated`);
            }
          })
          .catch(err => {
            this.savePermissionsError = err;
            if (this.title) {
              this.$notify.showError(`Error updating roles for ${this.title}: ${err}`);
            } else {
              this.$notify.showError(`Error updating roles: ${err}`);
            }
          })
          .finally(() => this.savingPermissions = false);
    },
    addRole(role) {
      if (!this.user) return;

      // Ensure that the roles array exists before pushing to it
      if (!this.user.roles) {
        // console.log(`Roles property didn't exist. Creating it now.`);
        this.$set(this.user, 'roles', []);
      }
      this.user.roles.push(role);
    },
    removeRole(role) {
      if (!this.user) return;
      this.user.roles = this.user.roles.filter(r => r !== role);
    },
    copyFields(data) {
      if (data) {
        if (data.exists) {
          this.user = cloneDecoratedData(data);
        }
      } else {
        this.user = null;
      }
    },
    linkTo(person) {
      this.linkingPerson = true;
      this.linkToPerson(person)
          .then(() => {
            const title = this.title ? `${this.title}` : '';
            this.$notify.showSuccess(`${title} has been linked successfully`);
            this.linkingDialog = false;
          })
          .catch(err => {
            this.$notify.showError(`${err}`);
          })
          .finally(() => this.linkingPerson = false);
    },
    async removePersonLink() {
      try {
        await this.unlinkPerson();
        this.$notify.showSuccess(`User person unlinked.`);
      } catch (e) {
        this.$notify.showError(`Error unlinking: ${e}`);
      }
    },
    selectAllSites(v) {
      this.$refs.siteEditor.setAll(v);
    },
    saveSiteChanges() {
      this.savingSites = true;
      const user = {sites: this.sites};
      // updateUser expects a ref property
      Object.defineProperty(user, 'ref', {value: this.user.ref, enumerable: false});
      this.updateUser(user)
          .then(() => {
            if (this.title) {
              this.$notify.showSuccess(`Sites for ${this.title} have been successfully updated`);
            } else {
              this.$notify.showSuccess(`Sites have been successfully updated`);
            }
          })
          .catch(err => {
            if (this.title) {
              this.$notify.showError(`Error updating sites for ${this.title}: ${err}`);
            } else {
              this.$notify.showError(`Error updating sites: ${err}`);
            }
          })
          .finally(() => this.savingSites = false);
    },
    async confirmDeleteUser() {
      const deleteUser = await functionUtil.userDeleteApi();
      deleteUser({
        ns: this.user.ns,
        email: this.user.email
      }).then(() => {
        this.removeUser(this.user.id);
        this.$notify.showSuccess('User deleted');
        this.$router.push({name: 'users'});
      }).catch(err => {
        this.$notify.showError(err);
      }).finally(() => {
        this.dialogOpen = false;
      });
    }
  }
}
;
</script>

<style scoped>
  .enable-pointers {
    /*
     * Disabling v-list-item (like we do when the role is implied) sets this to none. That means the tooltip activator
     * doesn't get any events and can't show why this role is deactivated.
     */
    pointer-events: initial !important;
  }

  /**
   * When the v-list-item is disabled the text colour is lightened.
   * This doesn't apply to the subtitle or action text.
   */
  .theme--light.v-list-item--disabled .v-list-item__subtitle,
  .theme--light.v-list-item--disabled .v-list-item__action-text {
    color: rgba(0, 0, 0, 0.38);
  }

  .role-label > .sticky-title {
    position: sticky;
    top: 0;
    background-color: white;
    /* Same as list-item__title */
    line-height: 1.3;
  }

  .sites-header {
    align-items: end;
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .sites-header h4 {
    grid-column: 1/3;
  }
</style>
