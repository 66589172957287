<template>
  <v-row dense v-if="!loading">
    <v-col cols="12" lg="6" class="pr-lg-6">
      <v-row dense>
        <v-col cols="12">
          <form-row :label="star('title', 'Full Name')">
            <v-text-field v-bind="formInputProps('title', 'Full Name')" v-model.trim="title"/>
          </form-row>
        </v-col>
        <v-col cols="12">
          <form-row :label="star('displayName', 'Display Name')">
            <v-text-field
                v-bind="formInputProps('displayName', 'Display Name')"
                v-model.trim="displayName"
                @input="displayNameReceivedInput = true"/>
          </form-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <form-row label="Custom Tags" combobox>
            <v-combobox
                v-bind="formInputProps('tags')"
                chips
                multiple
                v-model="tagsList"
                :items="knownTags"/>
          </form-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6">
      <v-row dense>
        <v-col cols="12">
          <form-row :label="star('employer.title', 'Company')">
            <v-text-field v-bind="formInputProps('employer.title', 'Company')" v-model="employerTitle"/>
          </form-row>
        </v-col>
        <v-col cols="12">
          <form-row>
            <v-text-field
                v-bind="formInputProps('position', 'Position')"
                :label="star('position', 'Position')"
                v-model.trim="position"/>
          </form-row>
        </v-col>
        <template v-if="departmentInputsEnabled">
          <v-col cols="12" class="mt-3">
            <form-row>
              <v-combobox
                  v-model="dptInputs.division"
                  label="Division"
                  :items="divisions"
                  variant="underlined"
                  class="pa-0"
                  clearable
                  chips
                  required
                  :rules="[v => !!v || 'Division is required']"
                  :error-messages="divisionError"/>
            </form-row>
            <div v-if="divisionRequiresSubfields">
              <form-row>
                <v-combobox
                    v-model="dptInputs.busgroup"
                    label="Business Group"
                    :items="busGroups"
                    variant="underlined"
                    class="pa-0"
                    clearable
                    chips
                    required
                    :rules="busGroupRules"
                    :error-messages="busGroupError"/>
              </form-row>
              <form-row>
                <v-combobox
                    v-model="dptInputs.busunit"
                    label="Business Unit"
                    :items="busUnits"
                    variant="underlined"
                    class="pa-0"
                    clearable
                    chips
                    required
                    :rules="busUnitRules"
                    :error-messages="busUnitError"/>
              </form-row>
            </div>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12">
            <form-row>
              <v-text-field
                  v-bind="formInputProps('department', 'Department')"
                  :label="star('department', 'Department')"
                  v-model.trim="department"/>
            </form-row>
          </v-col>
        </template>
        <v-col cols="12">
          <form-row :label="star('homeSite', 'Home Site')">
            <person-edit-home-site v-bind="formInputProps('homeSite', 'Home Site')" v-model="homeSite"/>
          </form-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else align="center" justify="center">
    <v-col cols="12" lg="6">
      <v-progress-circular indeterminate size="32" color="accent" width="2"/>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex';
import form, {formInputProps} from '@/components/form/form';
import FormRow from '@/components/form/FormRow';
import PersonEditHomeSite from '@/components/person/edit/PersonEditHomeSite';

export default {
  name: 'PersonEditAbout',
  components: {
    PersonEditHomeSite,
    FormRow
  },
  mixins: [
    formInputProps,
    form(['title', 'displayName', 'tags', 'employer.title', 'position', 'department', 'homeSite'])
  ],
  data() {
    return {
      displayNameReceivedInput: false,
      dptInputs: {
        division: '',
        busgroup: '',
        busunit: ''
      }
    };
  },
  computed: {
    ...mapState('views/person', ['departments']),
    ...mapGetters('views/person', ['loaded']),
    ...mapGetters('sites/active', ['departmentInputsEnabled']),
    ...mapState('ns', {
      nsDoc: 'doc'
    }),

    loading() {
      return !this.loaded;
    },
    /**
     * Capture all the tags we've seen from both the original object and that have been added via an edit.
     *
     * @return {string[]}
     */
    knownTags() {
      const tags = new Set();
      Object.keys(this.tags || {}).forEach((k) => tags.add(k));
      Object.keys(this.value.tags || {}).forEach((k) => tags.add(k));
      return Array.from(tags).sort();
    },
    /**
     * Converts between the string[] model needed by v-combobox and the {string:bool} model used by the person object.
     */
    tagsList: {
      get() {
        return Object.entries(this.tags || {})
            .filter(([, enabled]) => enabled)
            .map(([tag]) => tag);
      },
      set(v) {
        if (!this.tags) {
          this.tags = {};
        }
        const newTags = {};
        v.forEach((tag) => (newTags[tag] = true));
        Object.keys(this.tags).forEach((tag) => {
          if (!newTags[tag]) {
            this.tags[tag] = false;
          }
        });
        Object.keys(newTags).forEach((tag) => Vue.set(this.tags, tag, true));
      }
    },
    generatedDisplayName() {
      const firstSpace = this.title.indexOf(' ');
      return firstSpace < 0 ? this.title : this.title.substring(0, firstSpace);
    },
    divisions() {
      return this.departmentInputsEnabled ?
          this.departments.division.map((division) => division.name || division).sort() :
          [];
    },
    busGroups() {
      return this.departmentInputsEnabled ?
          this.departments.busgroup.map((busgroup) => busgroup.name || busgroup).sort() :
          [];
    },
    busUnits() {
      return this.departmentInputsEnabled ?
          this.departments.busunit.map((busunit) => busunit.name || busunit).sort() :
          [];
    },
    busGroupRules() {
      if (this.divisionRequiresSubfields) {
        return [v => !!v || 'Business Group is required'];
      }
      return [];
    },
    busUnitRules() {
      if (this.divisionRequiresSubfields) {
        return [v => !!v || 'Business Unit is required'];
      }
      return [];
    },
    divisionRequiresSubfields() {
      const div = this.departments.division.find((division) => division.name === this.dptInputs.division);
      return (!this.dptInputs.division || !div) || (this.dptInputs.division && div && div.requiresSubfields);
    },
    divisionError() {
      if (this.departmentInputsEnabled && !this.dptInputs.division) {
        return 'Division is required';
      }
      return '';
    },
    busGroupError() {
      if (this.departmentInputsEnabled && !this.dptInputs.busgroup) {
        return 'Business Group is required';
      }
      return '';
    },
    busUnitError() {
      if (this.departmentInputsEnabled && !this.dptInputs.busunit) {
        return 'Business Unit is required';
      }
      return '';
    }
  },
  watch: {
    dptInputs: {
      handler() {
        if (this.departmentInputsEnabled) {
          const division = this.dptInputs.division ? this.dptInputs.division.trim() : '';
          const busgroup = this.dptInputs.busgroup ? this.dptInputs.busgroup.trim() : '';
          const busunit = this.dptInputs.busunit ? this.dptInputs.busunit.trim() : '';

          let newDepartment = `"${division}", "", ""`;
          // Leave the busgroup & busunit empty if the division does not require them
          if (this.divisionRequiresSubfields) {
            if (division && busgroup) {
              newDepartment = `"${division}", "${busgroup}", ""`;
            }
            if (division && busgroup && busunit) {
              newDepartment = `"${division}", "${busgroup}", "${busunit}"`;
            }
          }
          if (newDepartment !== this.department) {
            this.department = newDepartment;
          }
        }
      },
      deep: true
    },
    generatedDisplayName(v, oldV) {
      if (!this.displayNameReceivedInput && oldV === this.displayName) {
        this.displayName = v;
      }
    }
  },
  beforeMount() {
    this.resetDptInputs();
    this.fillDptInputs();
    if (this.departmentInputsEnabled && !this.departments.division) {
      this.bindDepartments();
    }
  },
  methods: {
    ...mapActions('views/person', ['bindDepartments']),
    fillDptInputs() {
      if (this.department) {
        const departmentValues = this.department.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
        const [division, busGroup, busUnit] = departmentValues;
        this.dptInputs = {
          // Remove quotes from the fields
          division: division.replace(/["]+/g, ''),
          busgroup: busGroup ? busGroup.replace(/["]+/g, '') : '',
          busunit: busUnit ? busUnit.replace(/["]+/g, '') : ''
        };
      }
    },
    resetDptInputs() {
      this.dptInputs = {
        division: '',
        busgroup: '',
        busunit: ''
      };
    }
  }
};
</script>
