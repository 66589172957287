const {formatInTimeZone} = require('date-fns-tz');

/**
 * Helpers for interacting with a single booking.
 */
export class Booking {
  data;

  /**
   * @param {Object} data
   * @param {string} timezone
   */
  constructor(data, timezone = null) {
    this.data = data;
    this._site = null;
    this._space = null;
    this._floor = null;
    this._neighbourhood = null;
    this._owner = null;
    this._timezone = timezone || 'Europe/London';
  }

  hydrateSite(site) {
    this._site = site;
  }

  hydrateSpace(space) {
    this._space = space;
  }

  hydrateFloor(floor) {
    this._floor = floor;
  }

  hydrateNeighbourhood(neighbourhood) {
    this._neighbourhood = neighbourhood;
  }

  hydrateOwner(owner) {
    this._owner = owner;
  }

  /**
   * @return {Object}
   */
  get site() {
    return this._site || {};
  }

  /**
   * @return {Object}
   */
  get space() {
    return this._space || this.data.space || {};
  }

  /**
   * @return {Object|undefined}
   */
  get floor() {
    return this._floor || this.space.floor;
  }

  /**
   * @return {Object|undefined}
   */
  get neighbourhood() {
    return this._neighbourhood || this.space.neighbourhood;
  }

  /**
   * @return {Object}
   */
  get owner() {
    return this._owner || this.data.owner || {};
  }

  /**
   * @return {string}
   */
  get id() {
    return this.data.id;
  }

  /**
   * @return {boolean}
   */
  get requiresCheckIn() {
    return Boolean(this.data.requiresCheckIn);
  }

  /**
   * @return {Object}
   */
  get checkIn() {
    if (!this.data.checkIn) {
      return {};
    }
    return this.data.checkIn;
  }

  /**
   * @return {DocumentReference|undefined}
   */
  get spaceRef() {
    return this.space && this.space.ref;
  }

  /**
   * @return {DocumentReference|undefined}
   */
  get floorRef() {
    return this.floor && this.floor.ref;
  }

  /**
   * @return {DocumentReference|undefined}
   */
  get neighbourhoodRef() {
    return this.neighbourhood && this.neighbourhood.ref;
  }

  /**
   * @return {DocumentReference|undefined}
   */
  get ownerRef() {
    return this.owner && this.owner.ref;
  }

  /**
   * @return {DocumentReference|undefined}
   */
  get spaceKindRef() {
    return this.space && this.space.kind && this.space.kind.ref;
  }

  /**
   * @param {DocumentReference} kind
   * @return {boolean}
   */
  isSpaceKind(kind) {
    return kind && typeof kind.isEqual === 'function' && kind.isEqual(this.spaceKindRef);
  }

  /**
   * @return {string}
   */
  get ownerName() {
    // prefer title for the owner
    return this.owner && this.owner.title || '';
  }

  /**
   * @return {string}
   */
  get ownerEmail() {
    // prefer title for the owner
    return this.owner && this.owner.email || '';
  }

  /**
   * @return {string}
   */
  get createdViaTitle() {
    // prefer title for the app
    return this.data.createdVia && this.data.createdVia.title || '';
  }

  /**
   * @return {string}
   */
  get siteName() {
    return this._toName(this.site);
  }

  /**
   * @return {string}
   */
  get spaceName() {
    return this._toName(this.space);
  }

  /**
   * @return {string}
   */
  get floorName() {
    return this._toName(this.floor);
  }

  /**
   * @return {string}
   */
  get neighbourhoodName() {
    return this._toName(this.neighbourhood);
  }

  /**
   * @return {string}
   */
  get departmentName() {
    if (this.departmentIsSegmented) {
      let value = this.owner && this.owner.department;
      if (!value) {
        return '';
      }
      // split string by comma but ignore commas within quotes
      value = value.split(/, (?=(?:(?:[^"]*"){2})*[^"]*$)/);
      // extract value and remove quotes
      return value[0] ? value[0].replace(/["]+/g, '') : '';
    } else {
      return this.owner && this.owner.department;
    }
  }

  /**
   * @return {string}
   */
  get businessGroupName() {
    if (this.departmentIsSegmented) {
      let value = this.owner && this.owner.department;
      if (!value) {
        return '';
      }
      // split string by comma but ignore commas within quotes
      value = value.split(/, (?=(?:(?:[^"]*"){2})*[^"]*$)/);
      // extract value and remove quotes
      return value[1] ? value[1].replace(/["]+/g, '') : '';
    } else {
      return '';
    }
  }

  /**
   * @return {string}
   */
  get businessUnitName() {
    if (this.departmentIsSegmented) {
      let value = this.owner && this.owner.department;
      if (!value) {
        return '';
      }
      // split string by comma but ignore commas within quotes
      value = value.split(/, (?=(?:(?:[^"]*"){2})*[^"]*$)/);
      // extract value and remove quotes
      return value[2] ? value[2].replace(/["]+/g, '') : '';
    } else {
      return '';
    }
  }

  /**
   * @return {boolean}
   */
  get departmentIsSegmented() {
    if (this.owner && this.owner.department) {
      return this.owner.department.includes(', ');
    }
    return false;
  }

  /**
   * @return {string}
   */
  get ownerTags() {
    const tags = (this.owner && this.owner.tags) || {};
    return Object.keys(tags).filter(t => tags[t]).join(',');
  }

  /**
   * @return {null|Date}
   */
  get fromTime() {
    if (!this.data.fromTime) {
      return null;
    }
    return this.data.fromTime.toDate();
  }

  /**
   * @return {null|Date}
   */
  get toTime() {
    if (!this.data.toTime) {
      return null;
    }
    return this.data.toTime.toDate();
  }

  /**
   * @return {string}
   */
  get fromTimeString() {
    if (!this.fromTime) {
      return '';
    }
    return formatInTimeZone(this.fromTime, this._timezone, 'dd/MM/yyyy HH:mm');
  }

  /**
   * @return {string}
   */
  get fromTimeStringDate() {
    if (!this.fromTime) {
      return '';
    }
    return formatInTimeZone(this.fromTime, this._timezone, 'dd/MM/yyyy');
  }

  /**
   * @return {string}
   */
  get fromTimeStringTime() {
    if (!this.fromTime) {
      return '';
    }
    return formatInTimeZone(this.fromTime, this._timezone, 'HH:mm');
  }

  /**
   * @return {string}
   */
  get toTimeString() {
    if (!this.toTime) {
      return '';
    }
    return formatInTimeZone(this.toTime, this._timezone, 'dd/MM/yyyy HH:mm');
  }

  /**
   * @return {string}
   */
  get toTimeStringDate() {
    if (!this.toTime) {
      return '';
    }
    return formatInTimeZone(this.toTime, this._timezone, 'dd/MM/yyyy');
  }

  /**
   * @return {string}
   */
  get toTimeStringTime() {
    if (!this.toTime) {
      return '';
    }
    return formatInTimeZone(this.toTime, this._timezone, 'HH:mm');
  }

  /**
   * @return {null|firebase.firestore.Timestamp}
   */
  get checkedInTime() {
    if (!this.checkIn.fromTime) {
      return null;
    }
    return this.checkIn.fromTime.toDate();
  }

  /**
   * @return {string}
   */
  get checkedInString() {
    if (!this.requiresCheckIn) {
      return this.fromTimeString;
    }
    if (!this.checkedInTime) {
      return 'No';
    }
    return formatInTimeZone(this.checkedInTime, this._timezone, 'dd/MM/yyyy HH:mm');
  }

  /**
   * @return {null|firebase.firestore.Timestamp}
   */
  get checkedOutTime() {
    if (!this.checkIn.toTime) {
      return null;
    }
    return this.checkIn.toTime.toDate();
  }

  /**
   * @return {boolean}
   */
  get autoCheckedOut() {
    const lastWrittenVia = this.data.lastWrittenVia;
    return Boolean(lastWrittenVia && lastWrittenVia.ref && lastWrittenVia.ref.path === 'tools/auto-check-out');
  }

  /**
   * @return {string}
   */
  get checkedOutString() {
    if (!this.requiresCheckIn) {
      return 'N/A';
    }
    if (this.autoCheckedOut) {
      return 'Auto';
    }
    if (!this.checkedOutTime) {
      return 'No';
    }
    return formatInTimeZone(this.checkedOutTime, this._timezone, 'dd/MM/yyyy HH:mm');
  }

  /**
   * @param {Object} obj
   * @return {string}
   * @private
   */
  _toName(obj) {
    if (!obj) return '';
    if (obj.displayName) return obj.displayName;
    if (obj.title) return obj.title;
    return '';
  }
}
