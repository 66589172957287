<template>
  <span>
    {{ positionString }}<person-info-home-site v-if="showHomeSite" :person="person"/>
  </span>
</template>

<script>
import PersonInfoHomeSite from '@/components/person/PersonInfoHomeSite';
export default {
  name: 'PersonInfoPosition',
  components: {PersonInfoHomeSite},
  props: {
    person: {
      type: Object,
      default: null
    },
    showHomeSite: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    positionString() {
      if (!this.person) {
        return '';
      }
      const pos = [];
      if (this.person.hasOwnProperty('position') && this.person.position !== '') {
        pos.push(this.person.position.charAt(0).toUpperCase() + this.person.position.slice(1));
      }
      if (this.person.hasOwnProperty('department') && this.person.department !== '') {
        // remove quotes from the department string
        const department = this.person.department.replace(/["]+/g, '');
        pos.push(department.charAt(0).toUpperCase() +
            // replace trailing comma(s) and spaces if busgroup or busunit are not defined
            department.slice(1).replace(/,\s*,?\s*$/, ''));
      }
      if (this.person.hasOwnProperty('employer') && this.person.employer.title) {
        const v = this.person.employer.title;
        pos.push(v.charAt(0).toUpperCase() + v.slice(1));
      }
      return pos.join(', ');
    }
  }
};
</script>

<style scoped>

</style>
